const actions = {
  // generic actions
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  // load inventory events
  LOAD_WALLET_BALANCE: 'LOAD_WALLET_BALANCE',
  // LOAD_WITHDRAWABLE: 'LOAD_WITHDRAWABLE',
  LOADING_BALANCES: 'LOADING_BALANCES',
  BALANCES_LOADED: 'BALANCES_LOADED',
  LOADING_BALANCES_FAILED: 'LOADING_BALANCES_FAILED',
  LOADING_LIMIT: 'LOADING_LIMIT',
  LIMIT_LOADED: 'LIMIT_LOADED',
  LOADING_LIMIT_FAILED: 'LOADING_LIMIT_FAILED',
  LOAD_BANK_ACCOUNTS: 'LOAD_BANK_ACCOUNTS',
  LOADING_BANK_ACCOUNTS: 'LOADING_BANK_ACCOUNTS',
  BANK_ACCOUNTS_LOADED: 'BANK_ACCOUNTS_LOADED',
  LOADING_BANK_ACCOUNTS_FAILED: 'LOADING_BANK_ACCOUNTS_FAILED',
  // REQUEST OTP
  REQUEST_OTP: 'REQUEST_OTP',
  REQUESTING_OTP: 'REQUESTING_OTP',
  REQUESTED_OTP: 'REQUESTED_OTP',
  REQUEST_OTP_FAILED: 'REQUEST_OTP_FAILED',
  IS_ERROR_OTP: 'IS_ERROR_OTP',
  // VERIFY OTP
  VERIFY_OTP: 'VERIFY_OTP',
  VERIFYING_OTP: 'VERIFYING_OTP',
  VERIFIED_OTP: 'VERIFIED_OTP',
  RESET_VERIFIED_OTP: 'RESET_VERIFIED_OTP',
  VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',
  // RESEND OTP
  W_RESEND_OTP: 'W_RESEND_OTP',
  W_RESENDING_OTP: 'W_RESENDING_OTP',
  W_RESEND_OTP_SUCCESS: 'W_RESEND_OTP_SUCCESS',
  W_RESEND_OTP_FAILED: 'W_RESEND_OTP_FAILED',
  W_RESET_RESEND_OTP: 'W_RESET_RESEND_OTP',
  LOAD_SUPPORTED_BANKS: 'LOAD_SUPPORTED_BANKS',
  LOADING_SUPPORTED_BANKS: 'LOADING_SUPPORTED_BANKS',
  SUPPORTED_BANK_LOADED: 'SUPPORTED_BANK_LOADED',
  LOADING_SUPPORTED_BANKS_FAILED: 'LOADING_SUPPORTED_BANKS_FAILED',
  SEND_SMS_OTP: 'SEND_SMS_OTP',
  SENDING_SMS_OTP: 'SENDING_SMS_OTP',
  SMS_OTP_SENT: 'SMS_OTP_SENT',
  SENDING_SMS_OTP_FAILED: 'SENDING_SMS_OTP_FAILED',
  // RESET_OTP: 'RESET_OTP',
  SAVE_BANK_ACCOUNT: 'SAVE_BANK_ACCOUNT',
  SAVING_BANK_ACCOUNT: 'SAVING_BANK_ACCOUNT',
  BANK_ACCOUNT_SAVED: 'BANK_ACCOUNT_SAVED',
  SAVING_BANK_ACCOUNT_FAILED: 'SAVING_BANK_ACCOUNT_FAILED',
  // RESET_SAVING_NOTIF: 'RESET_SAVING_NOTIF',
  RESEND_OTP: 'RESEND_OTP',
  RESENDING_OTP: 'RESENDING_OTP',
  RESEND_OTP_SENT: 'RESEND_OTP_SENT',
  RESENDING_OTP_FAILED: 'RESENDING_OTP_FAILED',
  UPDATE_BANK_ACCOUNT: 'UPDATE_BANK_ACCOUNT',
  UPDATING_BANK_ACCOUNT: 'UPDATING_BANK_ACCOUNT',
  BANK_ACCOUNT_UPDATED: 'BANK_ACCOUNT_UPDATED',
  UPDATING_BANK_ACCOUNT_FAILED: 'UPDATING_BANK_ACCOUNT_FAILED',
  RESET_MESSAGE_NOTIF: 'RESET_MESSAGE_NOTIF',
  REMOVE_BANK_ACCOUNT: 'REMOVE_BANK_ACCOUNT',
  REMOVING_BANK_ACCOUNT: 'REMOVING_BANK_ACCOUNT',
  BANK_ACCOUNT_REMOVED: 'BANK_ACCOUNT_REMOVED',
  REMOVING_BANK_ACCOUNT_FAILED: 'REMOVING_BANK_ACCOUNT_FAILED',
  CHANGE_FAVORITE: 'CHANGE_FAVORITE',
  CHANGING_FAVORITE: 'CHANGING_FAVORITE',
  FAVORITE_CHANGED: 'FAVORITE_CHANGED',
  CHANGING_FAVORITE_FAILED: 'CHANGING_FAVORITE_FAILED',
  // CHANGE STATUS
  CHANGE_STATUS: 'CHANGE_STATUS',
  CHANGING_STATUS: 'CHANGING_STATUS',
  STATUS_CHANGED: 'STATUS_CHANGED',
  CHANGING_STATUS_FAILED: 'CHANGING_STATUS_FAILED',
  // SET TRANSACTION TYPE
  SET_TRANSACTION_TYPE: 'SET_TRANSACTION_TYPE',
  // WITHDRAWAL_TRANSACTION_REQUEST
  GET_WITHDRAWAL_TRANSACTION: 'GET_WITHDRAWAL_TRANSACTION',
  GETTING_WITHDRAWAL_TRANSACTION: 'GETTING_WITHDRAWAL_TRANSACTION',
  GET_WITHDRAWAL_TRANSACTION_SUCCESS: 'GET_WITHDRAWAL_TRANSACTION_SUCCESS',
  GET_WITHDRAWAL_TRANSACTION_ERROR: 'GET_WITHDRAWAL_TRANSACTION_ERROR',
  GET_WITHDRAWAL_TRANSACTION_FAILED: 'GET_WITHDRAWAL_TRANSACTION_FAILED',
  LOADMORE_WITHDRAWAL_TRANSACTION: 'LOADMORE_WITHDRAWAL_TRANSACTION',
  RESET_WITHDRAWAL_TRANSACTION: 'RESET_WITHDRAWAL_TRANSACTION',
  // SPLIT TRANSACTION_REQUEST
  GET_SPLIT_TRANSACTION: 'GET_SPLIT_TRANSACTION',
  GETTING_SPLIT_TRANSACTION: 'GETTING_SPLIT_TRANSACTION',
  GET_SPLIT_TRANSACTION_SUCCESS: 'GET_SPLIT_TRANSACTION_SUCCESS',
  GET_SPLIT_TRANSACTION_ERROR: 'GET_SPLIT_TRANSACTION_ERROR',
  GET_SPLIT_TRANSACTION_FAILED: 'GET_SPLIT_TRANSACTION_FAILED',
  LOADMORE_SPLIT_TRANSACTION: 'LOADMORE_SPLIT_TRANSACTION',
  UPDATE_SPLIT_TRANSACTION: 'UPDATE_SPLIT_TRANSACTION',
  RESET_SPLIT_TRANSACTION: 'RESET_SPLIT_TRANSACTION',
  // TOPUP TRANSACTION_REQUEST
  GET_TOPUP_TRANSACTION: 'GET_TOPUP_TRANSACTION',
  GETTING_TOPUP_TRANSACTION: 'GETTING_TOPUP_TRANSACTION',
  GET_TOPUP_TRANSACTION_SUCCESS: 'GET_TOPUP_TRANSACTION_SUCCESS',
  GET_TOPUP_TRANSACTION_ERROR: 'GET_TOPUP_TRANSACTION_ERROR',
  GET_TOPUP_TRANSACTION_FAILED: 'GET_TOPUP_TRANSACTION_FAILED',
  LOADMORE_TOPUP_TRANSACTION: 'LOADMORE_TOPUP_TRANSACTION',
  RESET_TOPUP_TRANSACTION: 'RESET_TOPUP_TRANSACTION',
  // ONLINE_PAYMENT TRANSACTION_REQUEST
  GET_ONLINE_PAYMENT_TRANSACTION: 'GET_ONLINE_PAYMENT_TRANSACTION',
  GETTING_ONLINE_PAYMENT_TRANSACTION: 'GETTING_ONLINE_PAYMENT_TRANSACTION',
  GET_ONLINE_PAYMENT_TRANSACTION_SUCCESS: 'GET_ONLINE_PAYMENT_TRANSACTION_SUCCESS',
  GET_ONLINE_PAYMENT_TRANSACTION_ERROR: 'GET_ONLINE_PAYMENT_TRANSACTION_ERROR',
  GET_ONLINE_PAYMENT_TRANSACTION_FAILED: 'GET_ONLINE_PAYMENT_TRANSACTION_FAILED',
  LOADMORE_ONLINE_PAYMENT_TRANSACTION: 'LOADMORE_ONLINE_PAYMENT_TRANSACTION',
  RESET_ONLINE_PAYMENT_TRANSACTION: 'RESET_ONLINE_PAYMENT_TRANSACTION',

  //AUTO SETTLEMENT REQUEST
  GET_ALL_AUTOSETTLEMENT: 'GET_ALL_AUTOSETTLEMENT',
  GETTING_ALL_AUTOSETTLEMENT: 'GETTING_ALL_AUTOSETTLEMENT',
  GOT_ALL_AUTOSETTLEMENT: 'GOT_ALL_AUTOSETTLEMENT',
  GET_ALL_AUTOSETTLEMENT_ERROR: 'GET_ALL_AUTOSETTLEMENT_ERROR',
  GET_ALL_AUTOSETTLEMENT_FAILED: 'GET_ALL_AUTOSETTLEMENT_FAILED',
  LOADMORE_AUTOSETTLEMENT: 'LOADMORE_AUTOSETTLEMENT',
  RESET_AUTOSETTLEMENT: 'RESET_AUTOSETTLEMENT',
  
  // FETCH WITHDRAWAL_REQUEST
  FETCH_WITHDRAWAL_REQUEST: 'FETCH_WITHDRAWAL_REQUEST',
  FETCHING_WITHDRAWAL_REQUEST: 'FETCHING_WITHDRAWAL_REQUEST',
  FETCH_WITHDRAWAL_REQUEST_SUCCESS: 'FETCH_WITHDRAWAL_REQUEST_SUCCESS',
  FETCH_WITHDRAWAL_REQUEST_ERROR: 'FETCH_WITHDRAWAL_REQUEST_ERROR',
  FETCH_WITHDRAWAL_REQUEST_FAILED: 'FETCH_WITHDRAWAL_REQUEST_FAILED',
  // DOWNLOAD WITHDRAW
  DOWNLOAD_WITHDRAWAL: 'DOWNLOAD_WITHDRAWAL',
  DOWNLOAD_WITHDRAWAL_LOADING: 'DOWNLOAD_WITHDRAWAL_LOADING',
  DOWNLOAD_WITHDRAWAL_LOADED: 'DOWNLOAD_WITHDRAWAL_LOADED',
  DOWNLOAD_WITHDRAWAL_ERROR: 'DOWNLOAD_WITHDRAWAL_ERROR',
  DOWNLOAD_WITHDRAWAL_MESSAGE: 'DOWNLOAD_WITHDRAWAL_MESSAGE',
  // DOWNLOAD SPLIT PAYMENT
  DOWNLOAD_SPLIT: 'DOWNLOAD_SPLIT',
  DOWNLOAD_SPLIT_LOADING: 'DOWNLOAD_SPLIT_LOADING',
  DOWNLOAD_SPLIT_LOADED: 'DOWNLOAD_SPLIT_LOADED',
  DOWNLOAD_SPLIT_ERROR: 'DOWNLOAD_SPLIT_ERROR',
  DOWNLOAD_SPLIT_MESSAGE: 'DOWNLOAD_SPLIT_MESSAGE',
  // DOWNLOAD TOPUP PAYMENT
  DOWNLOAD_TOPUP: 'DOWNLOAD_TOPUP',
  DOWNLOAD_TOPUP_LOADING: 'DOWNLOAD_TOPUP_LOADING',
  DOWNLOAD_TOPUP_LOADED: 'DOWNLOAD_TOPUP_LOADED',
  DOWNLOAD_TOPUP_ERROR: 'DOWNLOAD_TOPUP_ERROR',
  DOWNLOAD_TOPUP_MESSAGE: 'DOWNLOAD_TOPUP_MESSAGE',
  // DOWNLOAD BIZWALLET REPORT
  DOWNLOAD_BIZWALLET_REPORT: 'DOWNLOAD_BIZWALLET_REPORT',
  DOWNLOADING_BIZWALLET_REPORT: 'DOWNLOADING_BIZWALLET_REPORT',
  DOWNLOADED_BIZWALLET_REPORT: 'DOWNLOADED_BIZWALLET_REPORT',
  DOWNLOAD_BIZWALLET_REPORT_ERROR: 'DOWNLOADED_BIZWALLET_REPORT_ERROR',
  RESET_DOWNLOAD_BIZWALLET_REPORT: 'RESET_DOWNLOAD_BIZWALLET_REPORT',
  // DOWNLOAD AUTO SETTLEMENT
  DOWNLOAD_AUTO_SETTLEMENT: 'DOWNLOAD_AUTO_SETTLEMENT',
  DOWNLOAD_AUTO_SETTLEMENT_LOADING: 'DOWNLOAD_AUTO_SETTLEMENT_LOADING',
  DOWNLOAD_AUTO_SETTLEMENT_LOADED: 'DOWNLOAD_AUTO_SETTLEMENT_LOADED',
  DOWNLOAD_AUTO_SETTLEMENT_ERROR: 'DOWNLOAD_AUTO_SETTLEMENT_ERROR',
  DOWNLOAD_AUTO_SETTLEMENT_MESSAGE: 'DOWNLOAD_AUTO_SETTLEMENT_MESSAGE',
  // EMAIL NOTIF
  EMAIL_NOTIF: 'EMAIL_NOTIF',

  //UPDATE WITHDRAWAL STATUS
  UPDATE_WITHDRAWAL_STATUS: 'UPDATE_WITHDRAWAL_STATUS',
  UPDATING_WITHDRAWAL_STATUS: 'UPDATING_WITHDRAWAL_STATUS',
  UPDATED_WITHDRAWAL_STATUS: 'UPDATED_WITHDRAWAL_STATUS',
  UPDATING_FAILED_WITHDRAWAL_STATUS: 'UPDATING_FAILED_WITHDRAWAL_STATUS',

  // AUTO SWEEP OTP
  SEND_AUTOSWEEP_OTP: 'SEND_AUTOSWEEP_OTP',
  SENDING_AUTOSWEEP_OTP: 'SENDING_AUTOSWEEP_OTP',
  AUTOSWEEP_OTP_SENT: 'AUTOSWEEP_OTP_SENT',
  SENDING_AUTOSWEEP_OTP_FAILED: 'SENDING_AUTOSWEEP_OTP_FAILED',

  // AUTO SWEEP SAVING
  SAVE_AUTO_SWEEP: 'SAVE_AUTO_SWEEP',
  SAVING_AUTO_SWEEP: 'SAVING_AUTO_SWEEP',
  SAVED_AUTO_SWEEP: 'SAVED_AUTO_SWEEP',
  SAVING_AUTO_SWEEP_FAILED: 'SAVING_AUTO_SWEEP_FAILED',
  // WALLET TOP UP
  WALLET_TOP_UP: 'WALLET_TOP_UP',
  TOPPING_UP: 'TOPPING_UP',
  WALLET_TOP_UP_SUCCESS: 'WALLET_TOP_UP_SUCCESS',
  WALLET_TOP_UP_FAILED: 'WALLET_TOP_UP_FAILED',
  WALLET_TOP_UP_RESET: 'WALLET_TOP_UP_RESET',
  // CENTINEL TOP UP
  CENTINEL_TOP_UP: 'CENTINEL_TOP_UP',
  CENTINEL_TOPPING_UP: 'CENTINEL_TOPPING_UP',
  CENTINEL_TOP_UP_SUCCESS: 'CENTINEL_TOP_UP_SUCCESS',
  CENTINEL_TOP_UP_FAILED: 'CENTINEL_TOP_UP_FAILED',
  CENTINEL_TOP_UP_RESET: 'CENTINEL_TOP_UP_RESET',

  //SEND BOC REPORT
  SEND_REPORT_EMAIL: 'SEND_REPORT_EMAIL',
  SENDING_REPORT_EMAIL: 'SENDING_REPORT_EMAIL',
  DONE_SENDING_REPORT_EMAIL: 'DONE_SENDING_REPORT_EMAIL',
  RESET_REPORT_EMAIL: 'RESET_REPORT_EMAIL',
  ERROR_REPORT_EMAIL: 'ERROR_REPORT_EMAIL',

  // SEARCH WITHDRAW TRANSACTION
  SEARCH_WITHDRAW_TRANSACTION: 'SEARCH_WITHDRAW_TRANSACTION',
  SEARCHING_WITHDRAW_TRANSACTION: 'SEARCHING_WITHDRAW_TRANSACTION',
  SEARCHED_WITHDRAW_TRANSACTION: 'SEARCHED_WITHDRAW_TRANSACTION',
  SEARCH_WITHDRAW_TRANSACTION_FAILED: 'SEARCH_WITHDRAW_TRANSACTION_FAILED',
  SEARCH_WITHDRAW_TRANSACTION_ERROR: 'SEARCH_WITHDRAW_TRANSACTION_ERROR',
  SEARCH_LOADMORE_WITHDRAW_TRANSACTION: 'SEARCH_LOADMORE_WITHDRAW_TRANSACTION',
  RESET_SEARCH_WITHDRAW_TRANSACTION: 'RESET_SEARCH_WITHDRAW_TRANSACTION',

  // SEARCH SPLIT TRANSACTION
  SEARCH_SPLIT_TRANSACTION: 'SEARCH_SPLIT_TRANSACTION',
  SEARCHING_SPLIT_TRANSACTION: 'SEARCHING_SPLIT_TRANSACTION',
  SEARCHED_SPLIT_TRANSACTION: 'SEARCHED_SPLIT_TRANSACTION',
  SEARCH_SPLIT_TRANSACTION_FAILED: 'SEARCH_SPLIT_TRANSACTION_FAILED',
  SEARCH_SPLIT_TRANSACTION_ERROR: 'SEARCH_SPLIT_TRANSACTION_ERROR',
  SEARCH_LOADMORE_SPLIT_TRANSACTION: 'SEARCH_LOADMORE_SPLIT_TRANSACTION',
  RESET_SEARCH_SPLIT_TRANSACTION: 'RESET_SEARCH_SPLIT_TRANSACTION',

  // SEARCH TOPUP TRANSACTION
  SEARCH_TOPUP_TRANSACTION: 'SEARCH_TOPUP_TRANSACTION',
  SEARCHING_TOPUP_TRANSACTION: 'SEARCHING_TOPUP_TRANSACTION',
  SEARCHED_TOPUP_TRANSACTION: 'SEARCHED_TOPUP_TRANSACTION',
  SEARCH_TOPUP_TRANSACTION_FAILED: 'SEARCH_TOPUP_TRANSACTION_FAILED',
  SEARCH_TOPUP_TRANSACTION_ERROR: 'SEARCH_TOPUP_TRANSACTION_ERROR',
  SEARCH_LOADMORE_TOPUP_TRANSACTION: 'SEARCH_LOADMORE_TOPUP_TRANSACTION',
  RESET_SEARCH_TOPUP_TRANSACTION: 'RESET_SEARCH_TOPUP_TRANSACTION',

  // SEARCH ONLINE_PAYMENT TRANSACTION
  SEARCH_ONLINE_PAYMENT_TRANSACTION: 'SEARCH_ONLINE_PAYMENT_TRANSACTION',
  SEARCHING_ONLINE_PAYMENT_TRANSACTION: 'SEARCHING_ONLINE_PAYMENT_TRANSACTION',
  SEARCHED_ONLINE_PAYMENT_TRANSACTION: 'SEARCHED_ONLINE_PAYMENT_TRANSACTION',
  SEARCH_ONLINE_PAYMENT_TRANSACTION_FAILED: 'SEARCH_ONLINE_PAYMENT_TRANSACTION_FAILED',
  SEARCH_ONLINE_PAYMENT_TRANSACTION_ERROR: 'SEARCH_ONLINE_PAYMENT_TRANSACTION_ERROR',
  SEARCH_LOADMORE_ONLINE_PAYMENT_TRANSACTION: 'SEARCH_LOADMORE_ONLINE_PAYMENT_TRANSACTION',
  RESET_SEARCH_ONLINE_PAYMENT_TRANSACTION: 'RESET_SEARCH_ONLINE_PAYMENT_TRANSACTION',

  //SEARCH AUTO SETTLEMENT TRANSACTION
  SEARCH_AUTO_SETTLEMENT: 'SEARCH_AUTO_SETTLEMENT',
  SEARCHING_AUTO_SETTLEMENT: 'SEARCHING_AUTO_SETTLEMENT',
  SEARCHED_AUTO_SETTLEMENT: 'SEARCHED_AUTO_SETTLEMENT',
  SEARCH_AUTO_SETTLEMENT_FAILED: 'SEARCH_AUTO_SETTLEMENT_FAILED',
  SEARCH_AUTO_SETTLEMENT_ERROR: 'SEARCH_AUTO_SETTLEMENT_ERROR',
  SEARCH_LOADMORE_AUTO_SETTLEMENT: 'SEARCH_LOADMORE_AUTO_SETTLEMENT',
  RESET_SEARCH_AUTO_SETTLEMENT: 'RESET_SEARCH_AUTO_SETTLEMENT',

  // FETCH ENROLLED BANK ACCOUNT
  FETCH_ENROLLED_BANK_ACCOUNT: 'FETCH_ENROLLED_BANK_ACCOUNT',
  FETCHING_ENROLLED_BANK_ACCOUNT: 'FETCHING_ENROLLED_BANK_ACCOUNT',
  FETCHED_ENROLLED_BANK_ACCOUNT: 'FETCHED_ENROLLED_BANK_ACCOUNT',
  FETCH_ENROLLED_BANK_ACCOUNT_FAILED: 'FETCH_ENROLLED_BANK_ACCOUNT_FAILED',
  FETCH_ENROLLED_BANK_ACCOUNT_ERROR: 'FETCH_ENROLLED_BANK_ACCOUNT_ERROR',
  FETCH_LOADMORE_ENROLLED_BANK_ACCOUNT: 'FETCH_LOADMORE_ENROLLED_BANK_ACCOUNT',
  RESET_FETCH_ENROLLED_BANK_ACCOUNT: 'RESET_FETCH_ENROLLED_BANK_ACCOUNT',

  //FETCH ALL WALLETS OF MERCHANT
  FETCH_ALL_WALLETS: 'FETCH_ALL_WALLETS',
  FETCHING_ALL_WALLETS: 'FETCHING_ALL_WALLETS',
  FETCHED_ALL_WALLETS: 'FETCHED_ALL_WALLETS',
  FETCH_ALL_WALLETS_FAILED: 'FETCH_ALL_WALLETS_FAILED',
  SET_WALLET_ID: 'SET_WALLET_ID',

  //ADD WALLET
  SAVE_WALLET: 'SAVE_WALLET',
  SAVING_WALLET: 'SAVING_WALLET',
  SAVED_WALLET: 'SAVED_WALLET',
  SAVE_WALLET_FAILED: 'SAVE_WALLET_FAILED',
  RESET_SAVED_WALLET: 'RESET_SAVED_WALLET',

  //FETCH WALLET BY MID
  FETCH_WALLET_BY_MID: 'FETCH_WALLET_BY_MID',
  FETCHING_WALLET_BY_MID: 'FETCHING_WALLET_BY_MID',
  FETCHED_WALLET_BY_MID: 'FETCHED_WALLET_BY_MID',
  FETCH_WALLET_BY_MID_FAILED: 'FETCH_WALLET_BY_MID_FAILED',

  //APPROVE WALLET
  APPROVE_WALLET: 'APPROVE_WALLET',
  APPROVING_WALLET: 'APPROVING_WALLET',
  APPROVED_WALLET: 'APPROVED_WALLET',
  APPROVE_WALLET_FAILED: 'APPROVE_WALLET_FAILED',
  RESET_APPROVED_WALLET: 'RESET_APPROVED_WALLET',

  // ROLLING RESERVED
  FETCH_ROLLING_RESERVE: 'FETCH_ROLLING_RESERVE',
  FETCHING_ROLLING_RESERVE: 'FETCHING_ROLLING_RESERVE',
  FETCHED_ROLLING_RESERVE: 'FETCHED_ROLLING_RESERVE',
  FETCH_ROLLING_RESERVE_FAILED: 'FETCH_ROLLING_RESERVE_FAILED',
  RESET_ROLLING_RESERVE: 'RESET_ROLLING_RESERVE',

  // WALLET LINK
  FETCH_WALLET_LINK: 'FETCH_WALLET_LINK',
  FETCH_WALLET_LINK_LOADING: 'FETCH_WALLET_LINK_LOADING',
  FETCH_WALLET_LINK_SUCCESS: 'FETCH_WALLET_LINK_SUCCESS',
  FETCH_WALLET_LINK_FAILED: 'FETCH_WALLET_LINK_FAILED',

    // CANCEL_ONLINE_PAYMENT
    CANCEL_ONLINE_PAYMENT: 'CANCEL_ONLINE_PAYMENT',
    CANCEL_ONLINE_PAYMENT_LOADING: 'CANCEL_ONLINE_PAYMENT_LOADING',
    CANCEL_ONLINE_PAYMENT_SUCCESS: 'CANCEL_ONLINE_PAYMENT_SUCCESS',
    CANCEL_ONLINE_PAYMENT_FAILED: 'CANCEL_ONLINE_PAYMENT_FAILED',
    CANCEL_ONLINE_PAYMENT_RESET: 'CANCEL_ONLINE_PAYMENT_RESET',

    // SEND OTP CENTINEL MANUAL TOP UP
    SEND_OTP_CENTINEL_TOPUP: 'SEND_OTP_CENTINEL_TOPUP',
    SEND_OTP_CENTINEL_TOPUP_LOADING: 'SEND_OTP_CENTINEL_TOPUP_LOADING',
    SEND_OTP_CENTINEL_TOPUP_ERROR: 'SEND_OTP_CENTINEL_TOPUP_ERROR',
    SEND_OTP_CENTINEL_TOPUP_SUCCESS: 'SEND_OTP_CENTINEL_TOPUP_SUCCESS',
    SEND_OTP_CENTINEL_TOPUP_RESET: 'SEND_OTP_CENTINEL_TOPUP_RESET',

    // RESEND OTP CENTINEL MANUAL TOP UP
    RESEND_OTP_CENTINEL_TOPUP: 'RESEND_OTP_CENTINEL_TOPUP',
    RESEND_OTP_CENTINEL_TOPUP_LOADING: 'RESEND_OTP_CENTINEL_TOPUP_LOADING',
    RESEND_OTP_CENTINEL_TOPUP_ERROR: 'RESEND_OTP_CENTINEL_TOPUP_ERROR',
    RESEND_OTP_CENTINEL_TOPUP_SUCCESS: 'RESEND_OTP_CENTINEL_TOPUP_SUCCESS',
    RESEND_OTP_CENTINEL_TOPUP_RESET: 'RESEND_OTP_CENTINEL_TOPUP_RESET',

    // VERIFY OTP CENTINEL MANUAL TOP UP
    VERIFY_OTP_CENTINEL_TOPUP: 'VERIFY_OTP_CENTINEL_TOPUP',
    VERIFY_OTP_CENTINEL_TOPUP_LOADING: 'VERIFY_OTP_CENTINEL_TOPUP_LOADING',
    VERIFY_OTP_CENTINEL_TOPUP_ERROR: 'VERIFY_OTP_CENTINEL_TOPUP_ERROR',
    VERIFY_OTP_CENTINEL_TOPUP_SUCCESS: 'VERIFY_OTP_CENTINEL_TOPUP_SUCCESS',
    VERIFY_OTP_CENTINEL_TOPUP_RESET: 'VERIFY_OTP_CENTINEL_TOPUP_RESET',

  // FETCH MERCHANT BIR DOCUMENTS
  FETCH_MERCHANT_BIR_DOCUMENTS: 'FETCH_MERCHANT_BIR_DOCUMENTS',
  FETCH_MERCHANT_BIR_DOCUMENTS_SUCCESS: 'FETCH_MERCHANT_BIR_DOCUMENTS_SUCCESS',
  FETCH_MERCHANT_BIR_DOCUMENTS_LOADING: 'FETCH_MERCHANT_BIR_DOCUMENTS_LOADING',
  FETCH_MERCHANT_BIR_DOCUMENTS_FAILED: 'FETCH_MERCHANT_BIR_DOCUMENTS_FAILED',
  FETCH_MERCHANT_BIR_DOCUMENTS_RESET: 'FETCH_MERCHANT_BIR_DOCUMENTS_RESET',

  // SUBMIT MERCHANT BIR DOCUMENTS
  SUBMIT_MERCHANT_BIR_DOCUMENTS: 'SUBMIT_MERCHANT_BIR_DOCUMENTS',
  SUBMIT_MERCHANT_BIR_DOCUMENTS_SUCCESS: 'SUBMIT_MERCHANT_BIR_DOCUMENTS_SUCCESS',
  SUBMIT_MERCHANT_BIR_DOCUMENTS_LOADING: 'SUBMIT_MERCHANT_BIR_DOCUMENTS_LOADING',
  SUBMIT_MERCHANT_BIR_DOCUMENTS_FAILED: 'SUBMIT_MERCHANT_BIR_DOCUMENTS_FAILED',
  SUBMIT_MERCHANT_BIR_DOCUMENTS_RESET: 'SUBMIT_MERCHANT_BIR_DOCUMENTS_RESET',

  // UPDATE MERCHANT BIR DOCUMENTS STATUS
  UPDATE_BIR_DOCUMENTS_STATUS: 'UPDATE_BIR_DOCUMENTS_STATUS',
  UPDATE_BIR_DOCUMENTS_STATUS_SUCCESS: 'UPDATE_BIR_DOCUMENTS_STATUS_SUCCESS',
  UPDATE_BIR_DOCUMENTS_STATUS_LOADING: 'UPDATE_BIR_DOCUMENTS_STATUS_LOADING',
  UPDATE_BIR_DOCUMENTS_STATUS_FAILED: 'UPDATE_BIR_DOCUMENTS_STATUS_FAILED',
  UPDATE_BIR_DOCUMENTS_STATUS_RESET: 'UPDATE_BIR_DOCUMENTS_STATUS_RESET',

  // DELETE MERCHANT BIR DOCUMENTS
  DELETE_MERCHANT_BIR_DOCUMENTS: 'DELETE_MERCHANT_BIR_DOCUMENTS',
  DELETE_MERCHANT_BIR_DOCUMENTS_SUCCESS: 'DELETE_MERCHANT_BIR_DOCUMENTS_SUCCESS',
  DELETE_MERCHANT_BIR_DOCUMENTS_LOADING: 'DELETE_MERCHANT_BIR_DOCUMENTS_LOADING',
  DELETE_MERCHANT_BIR_DOCUMENTS_FAILED: 'DELETE_MERCHANT_BIR_DOCUMENTS_FAILED',
  DELETE_MERCHANT_BIR_DOCUMENTS_RESET: 'DELETE_MERCHANT_BIR_DOCUMENTS_RESET',

  // FETCH FILE BASE64 FROM AWS S3
  FETCH_FILE_BASE64: 'FETCH_FILE_BASE64',
  FETCH_FILE_BASE64_SUCCESS: 'FETCH_FILE_BASE64_SUCCESS',
  FETCH_FILE_BASE64_LOADING: 'FETCH_FILE_BASE64_LOADING',
  FETCH_FILE_BASE64_FAILED: 'FETCH_FILE_BASE64_FAILED',
  FETCH_FILE_BASE64_RESET: 'FETCH_FILE_BASE64_RESET',

  //REMOVAL OF ENROLLED BANK ACCOUNTS
  REQUEST_REMOVE_ENROLLED_BANK_OTP: 'REQUEST_REMOVE_ENROLLED_BANK_OTP',
  VERIFY_REMOVE_ENROLLED_BANK_OTP: 'VERIFY_REMOVE_ENROLLED_BANK_OTP',
  REQUESTING_REMOVE_ENROLLED_BANK_OTP: 'REQUESTING_REMOVE_ENROLLED_BANK_OTP',
  SET_OTP_REMOVE_BANNER: 'SET_OTP_REMOVE_BANNER',
  OTP_REMOVE_BANK_TOKEN: 'OTP_REMOVE_BANK_TOKEN',
  VERIFYING_REMOVE_BANK_OTP: 'VERIFYING_REMOVE_BANK_OTP',
  SET_OTP_REMOVE_MODAL: 'SET_OTP_REMOVE_MODAL',
  REMOVE_ENROLLED_BANK_ERROR: 'REMOVE_ENROLLED_BANK_ERROR',
  VALIDATED_REMOVE_ENROLLED_BANK_OTP: 'VALIDATED_REMOVE_ENROLLED_BANK_OTP',
  CLEAR_REMOVE_BANK_OTP_DATA: 'CLEAR_REMOVE_BANK_OTP_DATA',
  RESENDING_REMOVE_BANK_OTP: 'RESENDING_REMOVE_BANK_OTP',
  RESEND_REMOVE_BANK_OTP_FAILED: 'RESEND_REMOVE_BANK_OTP_FAILED',
  CLEAR_OTP_REMOVE_BANK_MESSAGE: 'CLEAR_OTP_REMOVE_BANK_MESSAGE',
  CLEAR_VERIFIED_OTP: 'CLEAR_VERIFIED_OTP',
  POP_REMOVE_BANK_BANNER: 'POP_REMOVE_BANK_BANNER',
  POP_REMOVE_BANK_MODAL: 'POP_REMOVE_BANK_MODAL',
  CONFIRM_REMOVAL_MODAL: 'CONFIRM_REMOVAL_MODAL',
  VERIFYING_ENROLLED_BANK_ERROR: 'VERIFYING_ENROLLED_BANK_ERROR',

  requestRemoveEnrolledBankOtp: (payload) => ({
    type: actions.REQUEST_REMOVE_ENROLLED_BANK_OTP,
    payload,
  }),
  verifyRemoveEnrolledBankOtp: (payload) => ({
    type: actions.VERIFY_REMOVE_ENROLLED_BANK_OTP,
    payload,
  }),
  clearOtpRemoveBankMessage: () => ({
    type: actions.CLEAR_OTP_REMOVE_BANK_MESSAGE,
  }),
  clearOtpRemoveBankData: () => ({
    type: actions.CLEAR_REMOVE_BANK_OTP_DATA,
  }),
  clearVerifiedOtp: () => ({
    type: actions.CLEAR_VERIFIED_OTP,
  }),
  popOtpRemoveBankBannerModal: () => ({
    type: actions.POP_REMOVE_BANK_BANNER,
  }),
  popOtpRemoveBankModal:() => ({
    type: actions.POP_REMOVE_BANK_MODAL,
  }),
  showconfirmRemovalModal: (payload) => ({
    type: actions.CONFIRM_REMOVAL_MODAL,
    payload,
  }),
  //END

  resetEmailNotif: () => ({
    type: actions.EMAIL_NOTIF,
    payload: null,
  }),
  setTransactionType: (payload) => ({
    type: actions.SET_TRANSACTION_TYPE,
    payload,
  }),
  getAllAutoSettlement: (payload) => ({
    type: actions.GET_ALL_AUTOSETTLEMENT,
    payload,
  }),
  getWithdrawalTransaction: (payload) => ({
    type: actions.GET_WITHDRAWAL_TRANSACTION,
    payload,
  }),
  updateSplitTransaction: (payload) => ({
    type: actions.UPDATE_SPLIT_TRANSACTION,
    payload,
  }),
  getSplitTransaction: (payload) => ({
    type: actions.GET_SPLIT_TRANSACTION,
    payload,
  }),
  getTopupTransaction: (payload) => ({
    type: actions.GET_TOPUP_TRANSACTION,
    payload,
  }),
  getOnlinePaymentTransaction: (payload) => ({
    type: actions.GET_ONLINE_PAYMENT_TRANSACTION,
    payload,
  }),
  loadBalance: () => ({
    type: actions.LOAD_WALLET_BALANCE,
  }),
  loadBankAccounts: () => ({
    type: actions.LOAD_BANK_ACCOUNTS,
  }),
  requestOtp: (payload) => ({
    type: actions.REQUEST_OTP,
    payload: payload,
  }),
  resetingOtp: () => ({
    type: actions.REQUESTED_OTP,
    payload: null,
    isErrorOtp: false,
  }),
  verifyOtp: (signature, payload, accountType) => ({
    type: actions.VERIFY_OTP,
    payload: payload,
    signature: signature,
    accountType: accountType,
  }),
  resetVerifyOtp: () => ({
    type: actions.RESET_VERIFIED_OTP,
    // isErrorVerifyOtp: false,
  }),
  loadSupportedBanks: () => ({
    type: actions.LOAD_SUPPORTED_BANKS,
  }),
  smsRequestBankAccount: (payload) => ({
    type: actions.SEND_SMS_OTP,
    payload: payload,
  }),
  // resetOtp: () => ({
  //   type: actions.RESET_OTP,
  // }),
  saveBankAccount: (payload) => ({
    type: actions.SAVE_BANK_ACCOUNT,
    payload: payload,
  }),
  // resetSavingNotif: () => ({
  //   type: actions.RESET_SAVING_NOTIF,
  // }),
  resendOtp: (payload) => ({
    type: actions.RESEND_OTP,
    payload: payload,
  }),
  wResendOtp: (payload) => ({
    type: actions.W_RESEND_OTP,
    payload: payload,
  }),
  resetResendOtp: () => ({
    type: actions.W_RESET_RESEND_OTP,
  }),
  updateBankAccount: (payload) => ({
    type: actions.UPDATE_BANK_ACCOUNT,
    payload: payload,
  }),
  removeBankAccount: (payload) => ({
    type: actions.REMOVE_BANK_ACCOUNT,
    payload: payload,
  }),
  changeFavorite: (payload) => ({
    type: actions.CHANGE_FAVORITE,
    payload: payload,
  }),
  resetMessageNotif: () => ({
    type: actions.RESET_MESSAGE_NOTIF,
  }),
  downloadWithdrawal: (payload) => ({
    type: actions.DOWNLOAD_WITHDRAWAL,
    payload: payload,
  }),
  resetDownloadWithdrawalMessage: () => ({
    type: actions.DOWNLOAD_WITHDRAWAL_MESSAGE,
    payload: null,
  }),
  downloadSplitPayment: (payload) => ({
    type: actions.DOWNLOAD_SPLIT,
    payload: payload,
  }),
  resetSplitPaymentMessage: () => ({
    type: actions.DOWNLOAD_SPLIT_MESSAGE,
    payload: null,
  }),
  downloadTopup: (payload) => ({
    type: actions.DOWNLOAD_TOPUP,
    payload: payload,
  }),
  resetDownloadTopupMessage: () => ({
    type: actions.DOWNLOAD_TOPUP_MESSAGE,
    payload: null,
  }),
  downloadBizwalletReport: (payload) => ({
    type: actions.DOWNLOAD_BIZWALLET_REPORT,
    payload: payload,
  }),
  resetDownloadBizwalletMessage: () => ({
    type: actions.RESET_DOWNLOAD_BIZWALLET_REPORT,
  }),
  downloadAutoSettlement: (payload) => ({
    type: actions.DOWNLOAD_AUTO_SETTLEMENT,
    payload: payload,
  }),
  resetDownloadAutoSettlementMessage: () => ({
    type: actions.DOWNLOAD_AUTO_SETTLEMENT_MESSAGE,
    payload: null,
  }),
  fetchWithdrawalRequest: (payload) => ({
    type: actions.FETCH_WITHDRAWAL_REQUEST,
    payload,
  }),
  updateStatus: (payload) => ({
    type: actions.UPDATE_WITHDRAWAL_STATUS,
    payload,
  }),
  clearUpdateStatus: () => ({
    type: actions.UPDATED_WITHDRAWAL_STATUS,
    payload: null,
  }),
  changeStatus: (payload) => ({
    type: actions.CHANGE_STATUS,
    payload: payload,
  }),
  smsRequestAutoSweep: (payload) => ({
    type: actions.SEND_AUTOSWEEP_OTP,
    payload: payload,
  }),
  saveAutoSweep: (payload, process) => ({
    type: actions.SAVE_AUTO_SWEEP,
    payload: payload,
    process: process,
  }),
  walletTopUp: (payload) => ({
    type: actions.WALLET_TOP_UP,
    payload: payload,
  }),
  resetWalletTopUp: () => ({
    type: actions.WALLET_TOP_UP_RESET,
  }),
  centinelTopUp: (payload) => ({
    type: actions.CENTINEL_TOP_UP,
    payload: payload,
  }),
  sendReportEmail: (payload) => ({
    type: actions.SEND_REPORT_EMAIL,
    payload: payload,
  }),
  resetCentinelTopUp: () => ({
    type: actions.CENTINEL_TOP_UP_RESET,
  }),
  resetReportEmail: () => ({
    type: actions.RESET_REPORT_EMAIL,
  }),
  searchWithdrawTransaction: (payload) => ({
    type: actions.SEARCH_WITHDRAW_TRANSACTION,
    payload: payload,
  }),
  searchSplitTransaction: (payload) => ({
    type: actions.SEARCH_SPLIT_TRANSACTION,
    payload: payload,
  }),
  searchTopupTransaction: (payload) => ({
    type: actions.SEARCH_TOPUP_TRANSACTION,
    payload: payload,
  }),
  searchOnlinePaymentTransaction: (payload) => ({
    type: actions.SEARCH_ONLINE_PAYMENT_TRANSACTION,
    payload: payload,
  }),
  searchAutoSettlement: (payload) => ({
    type: actions.SEARCH_AUTO_SETTLEMENT,
    payload: payload,
  }),
  resetWithdrawalTransaction: () => ({
    type: actions.RESET_WITHDRAWAL_TRANSACTION,
  }),
  resetSplitTransaction: () => ({
    type: actions.RESET_SPLIT_TRANSACTION,
  }),
  resetTopupTransaction: () => ({
    type: actions.RESET_TOPUP_TRANSACTION,
  }),
  resetOnlinePaymentTransaction: () => ({
    type: actions.RESET_ONLINE_PAYMENT_TRANSACTION,
  }),
  resetAutoSettlement: () => ({
    type: actions.RESET_AUTOSETTLEMENT,
  }),
  resetSearchWithdrawTransaction: () => ({
    type: actions.RESET_SEARCH_WITHDRAW_TRANSACTION,
  }),
  resetSearchSplitTransaction: () => ({
    type: actions.RESET_SEARCH_SPLIT_TRANSACTION,
  }),
  resetSearchTopupTransaction: () => ({
    type: actions.RESET_SEARCH_TOPUP_TRANSACTION,
  }),
  resetSearchOnlinePaymentTransaction: () => ({
    type: actions.RESET_SEARCH_ONLINE_PAYMENT_TRANSACTION,
  }),
  resetSearchAutoSettlement: () => ({
    type: actions.RESET_SEARCH_AUTO_SETTLEMENT,
  }),
  fetchEnrolledBankAccount: (payload) => ({
    type: actions.FETCH_ENROLLED_BANK_ACCOUNT,
    payload: payload,
  }),
  resetFetchEnrolledBankAccount: () => ({
    type: actions.RESET_FETCH_ENROLLED_BANK_ACCOUNT,
  }),
  fetchAllWallets: (payload) => ({
    type: actions.FETCH_ALL_WALLETS,
    payload: payload,
  }),
  setWalletId: (payload) => ({
    type: actions.SET_WALLET_ID,
    payload: payload,
  }),
  saveWallet: (payload) => ({
    type: actions.SAVE_WALLET,
    payload: payload,
  }),
  resetSavedWallet: () => ({
    type: actions.RESET_SAVED_WALLET,
  }),
  fetchWalletByMid: () => ({
    type: actions.FETCH_WALLET_BY_MID,
  }),
  approveWallet: (payload) => ({
    type: actions.APPROVE_WALLET,
    payload
  }),
  resetApprovedWallet: () => ({
    type: actions.RESET_APPROVED_WALLET
  }),
  fetchRollingReserve: (payload) => ({
    type: actions.FETCH_ROLLING_RESERVE,
    payload,
  }),
  resetRollingReserve: () => ({
    type: actions.RESET_ROLLING_RESERVE,
  }),
  fetchWalletLink: (payload) => ({
    type: actions.FETCH_WALLET_LINK,
    payload,
  }),
  cancelOnlinePayment: (payload) => ({
    type: actions.CANCEL_ONLINE_PAYMENT,
    payload,
  }),
  cancelOnlinePaymentReset: () => ({
    type: actions.CANCEL_ONLINE_PAYMENT_RESET,
  }),
  sendOtpCentinelTopup: (payload) => ({
    type: actions.SEND_OTP_CENTINEL_TOPUP,
    payload: payload,
  }),
  sendOtpCentinelTopupReset: () => ({
    type: actions.SEND_OTP_CENTINEL_TOPUP_RESET,
  }),
  resendOtpCentinelTopup: (payload) => ({
    type: actions.RESEND_OTP_CENTINEL_TOPUP,
    payload: payload,
  }),
  resendOtpCentinelTopupReset: () => ({
    type: actions.RESEND_OTP_CENTINEL_TOPUP_RESET,
  }),
  verifyOtpCentinelTopup: (payload) => ({
    type: actions.VERIFY_OTP_CENTINEL_TOPUP,
    payload: payload,
  }),
  verifyOtpCentinelTopupReset: () => ({
    type: actions.VERIFY_OTP_CENTINEL_TOPUP_RESET,
  }),
  fetchMerchantBirDocuments: (payload) => ({
    type: actions.FETCH_MERCHANT_BIR_DOCUMENTS,
    payload: payload
  }),
  fetchMerchantBirDocumentsReset: () => ({
    type: actions.FETCH_MERCHANT_BIR_DOCUMENTS_RESET,
  }),
  submitMerchantBirDocuments: (payload) => ({
    type: actions.SUBMIT_MERCHANT_BIR_DOCUMENTS,
    payload: payload
  }),
  submitMerchantBirDocumentsReset: () => ({
    type: actions.SUBMIT_MERCHANT_BIR_DOCUMENTS_RESET,
  }),
  updateBirDocumentsStatus: (payload) => ({
    type: actions.UPDATE_BIR_DOCUMENTS_STATUS,
    payload: payload
  }),
  updateBirDocumentsStatusReset: () => ({
    type: actions.UPDATE_BIR_DOCUMENTS_STATUS_RESET,
  }),
  deleteMerchantBirDocuments: (payload) => ({
    type: actions.DELETE_MERCHANT_BIR_DOCUMENTS,
    payload: payload
  }),
  deleteMerchantBirDocumentsReset: () => ({
    type: actions.DELETE_MERCHANT_BIR_DOCUMENTS_RESET,
  }),
  fetchFileBase64: (payload) => ({
    type: actions.FETCH_FILE_BASE64,
    payload: payload
  }),
  fetchFileBase64Reset: () => ({
    type: actions.FETCH_FILE_BASE64_RESET,
  }),
};

export default actions; 