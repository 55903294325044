import { all, put, fork, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import merchantActions from '../merchant/actions'
import { get, post, PUT, postBlob } from '@iso/lib/helpers/resApiRequestor';
import { AuthorizedGet } from '@iso/lib/helpers/resApiRequestorAuth';
import * as _ from 'lodash';
import netcore from '@iso/lib/helpers/netcore';
// import dummyProductTpl from './dummyProductTpl'


export function* loadAllInventory() {
  yield takeEvery('LOAD_ALL_INVENTORY', function* ({ payload }) {
    const state = yield select();
    const loadingInventory = state.Inventory.inventoryLoading;
    if (!loadingInventory) {
      try {
        yield put({
          type: actions.LOADING_INVENTORY,
        });
        yield put({
          type: actions.RESET_ALLOWED_PCHANNEL
        });
        const result = yield fetchInventoryByStore(payload.storeId);
        if (result.data.success) {
          yield put({
            type: actions.INVENTORY_LOADED,
            inventory: result.data.data,
            // inventoryDetails: result.data.inventoryDetails,
            scrollId: null,
            inventoryCount: 0,
          });
          try {
            yield put({
              type: actions.LOADING_ALLOWED_PCHANNEL
            });
            const apiResult = yield fetchAllowedChannels(payload.storeId);
            const result = apiResult.data;
            yield put({
              type: actions.ALLOWED_PCHANNEL_LOADED,
              pchannels: result.data.length > 0 ? result.data : [],
            });
          } catch (err) {
            yield put({
              type: actions.LOAD_ALLOWED_PCHANNEL_FAILED,
            });
          }
        }
      } catch (error) {
        yield put({
          type: actions.LOAD_INVENTORY_FAILED,
        });
      }
    }
  });
}

export function* loadInventoryLogs() {
  yield takeEvery('LOAD_INVENTORY_LOGS', function* ({ payload }) {
    const state = yield select();
    const logsLoading = state.Inventory.logsLoading;
    if (!logsLoading) {
      try {
        yield put({
          type: actions.LOADING_INVENTORY_LOGS,
        });
        const result = yield fetchInventoryLogs(payload.storeId, payload.itemCode);
        if (result.data.success) {
          yield put({
            type: actions.INVENTORY_LOGS_LOADED,
            inventoryLogs: result.data.data,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOAD_INVENTORY_LOGS_FAILED,
        });
      }
    }
  });
}

export function* loadInventoryDetails() {
  yield takeEvery('LOAD_INVENTORY_DETAILS', function* ({ payload }) {
    const state = yield select();
    const invDetailsLoading = state.Inventory.loadingInventoryDetails;
    if (!invDetailsLoading) {
      try {
        yield put({
          type: actions.LOADING_INVENTORY_DETAILS,
        });
        const result = yield fetchInventoryDetails(payload);
        if (result.data.success) {
          yield put({
            type: actions.LOADED_INVENTORY_DETAILS,
            data: result.data.data,
          });
        } else {
          yield put({
            type: actions.LOADED_INVENTORY_DETAILS,
            data: null,
          });
        }
      } catch (error) {
        yield put({
          type: actions.LOADING_INVENTORY_DETAILS_FAILED,
        });
      }
    }
  });
}

export function* addItem() {
  yield takeEvery('ADD_ITEM', function* ({ payload }) {
    try {
      yield put({
        type: actions.ITEM_SAVING,
      });
      const state = yield select();
      const apiResult = yield saveItem(payload);
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.ITEM_ADDED,
          result: result.success,
        });
        yield put({
          type: actions.ADD_EVENT_TRIGGERING,
          triggering: false,
        });

        // NETCORE START
        netcore({
          type: 'activity',
          email: state.Auth.identity.email,
          activityName: 'inventory_added',
          activityData: {
            sku_code: result.data.itemCode,
            price: result.data.itemAmount,
            unlimited_item: result.data.isUnlimited ? 'yes' : 'no',
            specific_item: !result.data.isUnlimited ? 'yes' : 'no',
            specific_item_quantity: result.data.quantity,
            shipping_setup: result.data.shippable ? 'yes' : 'no',
            serviceable_area: result.data.serviceableArea ? result.data.serviceableArea : '',
          }
        });
        // NETCORE END
      } else {
        yield put({
          type: actions.ITEM_ADD_FAILED,
        });
        yield put({
          type: actions.SHOW_ERROR_ADD_PRODUCT,
          payload: result.message,
        });
      }
    } catch (error) {
      yield put({
        type: actions.ITEM_ADD_FAILED,
      });
    }
  });
}

export function* replenishItem() {
  yield takeEvery('REPLENISH_ITEM', function* ({ payload }) {
    try {
      yield put({
        type: actions.ITEM_REPLENISHING,
      });
      const apiResult = yield updateItem(payload);
      const result = apiResult.data;
      yield put({
        type: actions.ITEM_REPLENISHED,
        result: result.success,
        data: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.ITEM_REPLENISH_FAILED,
      });
    }
  });
}

export function* editItem() {
  yield takeEvery('EDIT_ITEM', function* ({ payload }) {
    try {
      yield put({
        type: actions.ITEM_EDITING,
      });
      const apiResult = yield updateItem(payload);
      const result = apiResult.data;
      yield put({
        type: actions.ITEM_EDITED,
        result: result.success,
        data: result.data,
      });
    } catch (error) {
      yield put({
        type: actions.ITEM_EDIT_FAILED,
      });
    }
  });
}

export function* deleteItem() {
  yield takeEvery('DELETE_ITEM', function* ({ payload }) {
    const state = yield select();
    try {
      yield put({
        type: actions.ITEM_DELETING,
      });
      const apiResult = yield removeItem(payload);
      const result = apiResult.data;
      const inventory = state.Inventory.inventory;
      inventory.data = _.reject(inventory.data, { itemCode: payload.itemCode });
      yield put({
        type: actions.ITEM_DELETED,
        result: result.success,
        inventory: inventory,
      });
    } catch (error) {
      yield put({
        type: actions.ITEM_DELETING_FAILED,
      });
    }
  });
}
export function* getQrImage() {
  yield takeEvery('GET_QR_IMAGE', function* ({ payload }) {
    try {
      yield put({
        type: actions.GETTING_QR_IMAGE,
        payload: true,
      });
      const apiResult = yield getQr(payload.Id);
      const result = apiResult.data;
      yield put({
        type: actions.GETTING_QR_IMAGE,
        payload: false,
      });
      if (result.success) {
        yield put({
          type: actions.QR_IMAGE,
          payload: result.data, // put the base64 image here
          productUrl: result.url,
        });
      }
    } catch (error) {
      yield put({
        type: actions.GET_QR_IMAGE_FAILED,
      });
    }
  });
}
export function* downloadQrPdf() {
  yield takeEvery('DOWNLOAD_QR_PDF', function* ({ payload }) {
    try {
      yield put({
        type: actions.DOWNLOADING_QR_PDF,
        payload: true,
      });
      const apiResult = yield generateQr({
        itemId: payload.Id,
        itemName: payload.itemDescription,
      });
      const result = apiResult.data;
      yield put({
        type: actions.DOWNLOADING_QR_PDF,
        payload: false,
      });
      if (result) {
        // const dlApiResult = yield downloadQr({ filename: result.data });
        // const dlResult = dlApiResult.data;
        yield put({
          type: actions.DOWNLOADED_QR_PDF,
          payload: result,
          // payload: dlResult,
        });
      }
    } catch (error) {
      yield put({
        type: actions.DOWNLOAD_QR_PDF_FAILED,
      });
    }
  });
}
export function* fetchProductById() {
  yield takeEvery('FETCH_PRODUCT_BY_ID', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCHING_PRODUCT_BY_ID,
      });
      const result = yield fetchInvById(payload);
      if (result.status === 200) {
        if (result.data.success) {
          const checkVerifiedStore = yield checkVerified(result.data.data.storeId);
          if (checkVerifiedStore.status === 200 && checkVerifiedStore.data.store === 'NOT VERIFIED') {
            yield put({
              type: actions.FETCH_PRODUCT_BY_ID_FAILED,
              payload: 'Payment via scan QR is not yet activated for this merchant. KYC approval is required.',
            });
          } else {
            yield put({
              type: actions.FETCHED_PRODUCT_BY_ID,
              payload: result.data.data,
            });
          }
        } else {
          yield put({
            type: actions.FETCH_PRODUCT_BY_ID_FAILED,
            payload: result.data.message,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PRODUCT_BY_ID_FAILED,
        payload: 'Item not found!',
      });
    }
  });
}
export function* fetchProductTpl() {
  yield takeEvery('FETCH_PRODUCT_TPL', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCHING_PRODUCT_TPL,
      });
      const apiResult = yield fetchInventoryTemplate();
      if (apiResult.status === 200) {
        if (apiResult.data.success) {
          yield put({
            type: actions.FETCHED_PRODUCT_TPL,
            payload: apiResult.data.data.result,
          });
        } else {
          yield put({
            type: actions.FETCH_PRODUCT_TPL_FAILED,
            payload: apiResult.data.message,
          });
        }
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_PRODUCT_TPL_FAILED,
      });
    }
  });
}
export function* uploadBanner() {
  yield takeEvery('UPLOAD_BANNER', function* ({ payload }) {
    try {
      yield put({
        type: actions.UPLOADING_BANNER
      });
      const apiResult = yield updateItemBanner(payload[0]);
      const result = apiResult.data;
      if (apiResult.data.success) {
        yield put({
          type: actions.UPLOADED_BANNER,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.UPLOAD_BANNER_FAILED,
          message: 'Banner upload failed.'
        });
      }
    } catch (error) {
      yield put({
        type: actions.UPLOAD_BANNER_FAILED,
        message: 'Banner upload failed.'
      });
    }
  });
}
export function* saveInventoryDetails() {
  yield takeEvery('SAVE_INVENTORY_DETAILS', function* ({ payload }) {
    const state = yield select();
    try {
      yield put({
        type: actions.SAVING_INVENTORY_DETAILS
      });
      // console.log("PAYLOAD_INVENTORY", payload);
      let apiResult;
      if (!payload.id) {
        apiResult = yield insertInventoryDetails(payload);
      } else {
        apiResult = yield updateInventoryDetails(payload);
      }
      const result = apiResult.data;
      if (result.success) {
        const mIndex = payload.merchIndex !== undefined ? payload.merchIndex : "";
        const iIndex = payload.invIndex !== undefined ? payload.invIndex : "";
        if(mIndex !== "" && iIndex !== "" ) {
          const mIndex = payload.merchIndex;
          const iIndex = payload.invIndex;

          yield put({
            type: merchantActions.FETCHING_MERCHANT_STORE_INVENTORY,
          });

          const fetchedMerchantStoreInventory = state.Merchant.fetchedMerchantStoreInventory;
          fetchedMerchantStoreInventory[mIndex].inventoryInfo[iIndex].body = payload.body;
          fetchedMerchantStoreInventory[mIndex].inventoryInfo[iIndex].images = payload.images;
          yield put({
            type: merchantActions.FETCHED_MERCHANT_STORE_INVENTORY,
            payload: fetchedMerchantStoreInventory,
          });

          yield put({
            type: merchantActions.CHECK_COURIER_ISSUE_PICK_UP,
            payload: false,
          });
          yield put({
            type: merchantActions.CHECK_COURIER_ISSUE_PRODUCT_SPECS,
            payload: false,
          });

        }
        yield put({
          type: actions.SAVED_INVENTORY_DETAILS,
          data: result.data,
        });
      } else {
        yield put({
          type: actions.SAVE_INVENTORY_DETAILS_FAILED,
          message: result.message
        });
      }
    } catch (error) {
      yield put({
        type: actions.SAVE_INVENTORY_DETAILS_FAILED,
        message: 'Error saving inventory details, please try again'
      });
    }
  });
}
export function* fetchShippingRate() {
  yield takeEvery('FETCH_SHIPPING_RATE', function* ({ payload }) {
    try {
      yield put({
        type: actions.FETCHING_SHIPPING_RATE
      });
      let apiResult = yield fetchingShippingRate(payload);
      const result = apiResult.data;
      yield put({
        type: actions.FETCHED_SHIPPING_RATE,
        payload: result.hasShippingSetup,
        message: result.hasShippingSetup ? 'Has Shipping Rate' : 'Has no Shipping Rate',
      });
    } catch (error) {
      yield put({
        type: actions.FETCH_SHIPPING_RATE_FAILED,
      });
    }
  });
}
export function* fetchBranch() {
  yield takeEvery('FETCH_BRANCH', function* () {
    try {
      yield put({
        type: actions.FETCHING_BRANCH
      });
      let apiResult = yield fetchingBranch();
      const result = apiResult.data;
      if (result.success) {
        yield put({
          type: actions.FETCHED_BRANCH,
          payload: result.data,
        });
      } else {
        yield put({
          type: actions.FETCH_BRANCH_FAILED,
        });
      }
    } catch (error) {
      yield put({
        type: actions.FETCH_BRANCH_FAILED,
      });
    }
  });
}

function fetchInvById(id) {
  return get(`inventories/fetch_by_id/${id}`);
}
function fetchingBranch() {
  return AuthorizedGet(`merchantbranches/get/branch`);
}
function fetchingShippingRate(id) {
  return get(`shippingsetups/shipping_setup_validator/${id}`);
}
function generateQr(data) {
  return postBlob(`inventories/download_product_qr`, data);
}
// function downloadQr(data) {
//   return postBlob(`stores/get_pdf`, data);
// }
function fetchInventoryByStore(id) {
  return get(`inventories/get_product/ui_new/${id}`);
}
function getQr(id) {
  return get(`inventories/get_product_qr/${id}`);
}
function fetchAllowedChannels(storeId) {
  return get(`storechannels/get_pchannel_by_store_id/${storeId}`);
}
function fetchInventoryLogs(storeId, itemCode) {
  const data = {
    storeId,
    itemCode,
  }
  return post(`inventorylogs/find_by_item_code`, data);
}
function saveItem(data) {
  return post(`inventories/create_product_app`, data);
}
function updateItem(data) {
  return post(`inventories/update_product_app`, data);
}
function removeItem(data) {
  return post(`inventories/delete_product_app`, data);
}
function updateItemBanner(data) {
  return post(`inventories/upload_product_banner/`, data);
}
function fetchInventoryTemplate() {
  return get(`inventorytemplates/`);
}
function fetchInventoryDetails(id) {
  return get(`inventorydetails/get_by_product_id/${id}`);
}
function insertInventoryDetails(data) {
  return post(`inventorydetails/`, data);
}
function updateInventoryDetails(data) {
  return PUT(`inventorydetails/${data.id}`, data);
}
function checkVerified(id) {
  return get(`merchants/check_verified/${id}`);
}

export default function* rootSaga() {
  yield all([
    fork(loadAllInventory),
    fork(loadInventoryLogs),
    fork(addItem),
    fork(replenishItem),
    fork(editItem),
    fork(deleteItem),
    fork(getQrImage),
    fork(downloadQrPdf),
    fork(fetchProductById),
    fork(fetchProductTpl),
    fork(uploadBanner),
    fork(saveInventoryDetails),
    fork(loadInventoryDetails),
    fork(fetchShippingRate),
    fork(fetchBranch),
  ]);
}